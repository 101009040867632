.fallback {
    width: 100vw;
    height: 100vh;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    display: flex;

    &__img {
        max-height: 180px;
    }

    &__title {
        padding: 10px;
        font-size: 22px;
    }

    &__describe {
        padding: 10px;
        font-size: 18px;
    }

    &__link {
        color: var(--text-primary);

        padding: 10px;

        &:hover {
            color: var(--red);
        }

        &:active {
            color: var(--dark-red);
        }
    }
}
